export const baseurl = process.env.REACT_APP_BASE_URL; // live url
export const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY; // live url

// export const baseurl = "http://172.16.1.217:2136"; // vishnu local
// export const baseurl = "https://nodeadmin-saasarbitrage.mobiloitte.io"; // staging url
// export const reCaptchaKey = "6LfUy9UpAAAAAOem85goMJaUQYtdegN3jD9Ih-Z6"; // vishnu local

const url = `${baseurl}/api/v1`;
const ApiConfig = {
  login: `${url}/admin/login`,
  getProfile: `${url}/admin/getProfile`,
  forgotPassword: `${url}/admin/forgotPassword`,
  verifyOTP: `${url}/admin/verifyOTP`,
  resetPassword: `${url}/admin/resetPassword`,
  resendOTP: `${url}/admin/resendOTP`,
  dashboard: `${url}/admin/dashboard`,
  statistic: `${url}/admin/statistic`,

  // User Mangement
  blockUnblockUser: `${url}/admin/blockUnblockUser`,
  deleteUser: `${url}/admin/deleteUser`,

  //sub admin
  createSubAdmin: `${url}/admin/createSubAdmin`,
  updateSubAdmin: `${url}/admin/updateSubAdmin`,
  blockUnblockSubAdmin: `${url}/admin/blockUnblockSubAdmin`,
  deleteSubAdmin: `${url}/admin/deleteSubAdmin`,
  subAdminList: `${url}/admin/subAdminList`,

  /// exchange
  listExchange: `${url}/wallet/listExchange`,

  // add plan
  addPlan: `${url}/subscription/addPlan`,
  editPlan: `${url}/subscription/editPlan`,
  getPlan: `${url}/subscription/getPlan`,
  deletePlan: `${url}/subscription/deletePlan`,
  activeBlockPlan: `${url}/subscription/activeBlockPlan`,
  subscriptionPlanList: `${url}/admin/subscriptionPlanList`,

  // add User
  addUser: `${url}/admin/addUser`,
  editUser: `${url}/admin/editUser`,
  deleteUser: `${url}/admin/deleteUser`,
  blockUnblockUser: `${url}/admin/blockUnblockUser`,
  getUserList: `${url}/admin/getUserList`,

  // static
  listStaticContent: `${url}/static/listStaticContent`,
  editStaticContent: `${url}/static/editStaticContent`,
  replyContactUs: `${url}/static/replyContactUs`,
  listAllContactUsRequest: `${url}/static/listAllContactUsRequest`,
};
export default ApiConfig;
