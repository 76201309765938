import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { typography } from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiIconButton: {
      root: {
        color: "#e1e1e1",
      },
    },
    MuiTableContainer: {
      root: {
        borderRadius: "10px",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "transparent",
        fontSize: "14px",
        whiteSpace: "pre",
        color: "#fff",
        padding: "20px 12px",
        background: "#FFFFFF08",
      },
      body: {
        color: "#e1e1e1",
        fontSize: "13px",
        padding: "13px",
      },
      head: {
        // padding: "16px 12px",
        color: "#fff",
        fontWeight: 600,
        background: "#FFFFFF08",
        borderBottom: "1px solid #FFFFFF08",
      },
    },
    MuiTableRow: {
      root: {},
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "5px",
        fontSize: "14px",
        height: "45px",
        fontFamily: "'Sora Condensed', sans-serif",
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        border: "none",
        "& svg": {
          color: "#FFFFFF99",
          fontSize: "24px",
        },
      },
      notchedOutline: { borderColor: "transparent" },
      input: {
        // borderRadius: "10px",
        fontWeight: "400",
        color: "#fff",
        padding: "10px 13px",
        "&:-webkit-autofill": {
          "-webkit-background-clip": "text !important",
          // transitionDelay: "9999s",
          "caret-color": "transparent",
          "-webkit-box-shadow": "0 0 0 100px transparent inset",
          "-webkit-text-fill-color": "#fff",
        },
        "&:-internal-autofill-selected": {
          color: "#fff",
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#1C1A1E",
      },
      outlined: {
        padding: "20px",
        width: "100%",
      },
      elevation1: {
        height: "100%",
        backgroundColor: "#1D1B1F",
        borderRadius: "12px",
        padding: "24px",
        "@media(max-width:959px)": {
          height: "auto",
        },
        "@media(max-width:767px)": {
          padding: "15px",
        },
      },
      elevation2: {
        backgroundColor: "#1C1A1E",
        borderRadius: "8px",
        padding: "25px",
        "@media(max-width:767px)": {
          padding: "20px",
        },
      },
      elevation3: {
        padding: "20px",
        background: "#1C1A1E",
        borderRadius: "20px",
        position: "relative",
        boxShadow: "0px 0px 10px 1px #8247FF",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiMenuItem: {
      root: {
        color: "#FFFFFF",
        fontSize: "14px",
        paddingLeft: "8px",
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
        marginBottom: "10px",
        width: "100%",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        // "&.Mui-checked": { color: "#10C6FF" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiFormHelperText: {
      root: {
        marginLeft: "0px !important",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      borderRadius: "20px",
      paperScrollPaper: {
        Width: 550,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
      },
      paperFullWidth: {
        background: "#1C1A1E",
        borderRadius: "20px",
      },
    },
    MuiPickersModal: {
      dialogRoot: {
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        borderRadius: "20px",
      },
    },
    MuiInputBase: {
      input: {
        color: "rgba(255, 255, 255, 0.6)",
        fontSize: 14,
        fontWeight: 400,
      },
    },

    MuiDivider: {
      root: {
        backgroundColor: "rgba(112, 112, 112)",
      },
      vertical: {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      },
    },
    MuiAutocomplete: {
      paper: {
        padding: 0,
      },
      option: {
        fontFamily: "Sora Condensed",
        fontSize: "12px !important",
        fontWeight: "400 !important",
        lineHeight: "18px !important",
        letterSpacing: "0px !important",
        textAlign: "left !important",
      },
      inputRoot: {
        "& .MuiAutocomplete-input": {
          padding: "5.5px 3px !important",
        },
      },
    },
    MuiButton: {
      containedSecondary: {
        color: "#fff",
        height: "45px",
        padding: "10px 35px",
        fontSize: "16px",
        // border: "1px solid #E1E1E1",
        backgroundColor: "#FFFFFF1A",
        fontWeight: "400",
        lineHeight: "20px",
        fontFamily: "'Sora'",
        borderRadius: "6px",
        "&:hover": {
          backgroundColor: "#8247FF",
          boxShadow: "none !important",
        },

        "& .Mui-disabled": {
          color: "transparent !important",
          backgroundColor: "transparent !important",
        },
      },

      "& .Mui-disabled": {
        color: "transparent !important",
        backgroundColor: "transparent !important",
      },
      MuiDisabled: {
        color: "transparent !important",
        backgroundColor: "transparent !important",
      },
      containedPrimary: {
        color: "#fff",
        height: "45px",
        padding: "10px 35px",
        fontSize: "16px",
        backgroundColor: "#8247FF",
        fontWeight: "400",
        lineHeight: "20px",
        fontFamily: "'Sora'",
        whiteSpace: "pre",
        borderRadius: "6px",
        "@media(max-width:599px)": {
          fontSize: "13px",
        },
        "&:hover": {
          backgroundColor: "#FFFFFF1A",
        },
        "& .Mui-disabled": {
          color: "transparent !important",
          backgroundColor: "none !important",
        },
      },
      contained: {
        borderRadius: "50px",
        fontWeight: 600,
        padding: "5px 19px",
        boxShadow: "none !important",
        "& .Mui-disabled": {
          color: "transparent !important",
          backgroundColor: "none !important",
        },
      },
      outlinedPrimary: {
        color: "#000",
        border: "1px solid #10C6FF",
        padding: "5px 25px",
        fontWeight: "500",
        borderRadius: "50px",
        fontSize: "13px",
        "&:hover": {
          backgroundColor: "#E1E1E1",
          color: "#000",
        },
        "& .Mui-disabled": {
          color: "transparent !important",
          backgroundColor: "none !important",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    PrivateTabIndicator: {
      root: {
        backgroundColor: "transparent !important",
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#26252E",
          borderRadius: "8px !important",
        },
      },
    },

    MuiTypography: {
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },

    MuiPickersCalendarHeader: {
      dayLabel: {
        color: "#fff",
      },
      iconButton: {
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        color: "#fff",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
      },
    },

    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "rgba(0, 0, 0)",
        borderRadius: "20px",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "#fff",
      },
      toolbarBtnSelected: {
        color: "#fff",
        marginTop: "4px",
      },
    },
    MuiTab: {
      root: {
        backgroundColor: "#fff",
        color: "#000 !important",
        borderRadius: "4px",
        height: "45px",
        padding: "4px 12px",
        margin: "0 5px",
        fontSize: "16px",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Sora', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#ffffff",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#FFFFFFa6",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff0010",
      dark: "#ff0010",
      light: "#ffe9e6",
    },
    text: {
      primary: "#FFFFFF80",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
