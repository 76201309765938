import axios from "axios";
import toast from "react-hot-toast";
import ApiConfig from "../ApiConfig";

export const apiRouterCall = async ({
  method,
  url,
  bodyData,
  paramsData,
  token,
}) => {
  try {
    return await axios({
      method: method,
      url: url,
      headers: {
        token: token ? token : window.sessionStorage.getItem("token"),
      },
      data: bodyData ? bodyData : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    if (error.response) {
      return error?.response;
    } else {
      toast.error(error.message);
      return;
    }
  }
};
export const dataPostHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(" error ", error);
    if (error.response) {
      if (error.response.status === 440) {
        window.location.href = "/";
      }
      if (error.response.status === 403) {
        window.localStorage.removeItem("access_token");
      }
      return error?.response;
    }
  }
};

export const postAPIHandler = async ({
  endPoint,
  dataToSend,
  paramsData,
  id,
}) => {
  try {
    return await axios({
      method: "POST",
      url: id ? `${ApiConfig[endPoint]}/${id}/` : ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const putAPIHandler = async ({
  endPoint,
  dataToSend,
  paramsData,
  id,
}) => {
  try {
    return await axios({
      method: "PUT",
      url: id ? `${ApiConfig[endPoint]}/${id}/` : ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteAPIHandler = async ({
  endPoint,
  dataToSend,
  paramsData,
  id,
}) => {
  try {
    return await axios({
      method: "DELETE",
      url: id ? `${ApiConfig[endPoint]}/${id}` : ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAPIHandler = async ({ endPoint, id, source, paramsData }) => {
  try {
    return await axios({
      method: "GET",
      url: id ? `${ApiConfig[endPoint]}/${id}/` : ApiConfig[endPoint],
      params: paramsData ? paramsData : null,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      cancelToken: source ? source.token : null,
    });
  } catch (error) {
    console.log(error);
  }
};
export const patchAPIHandler = async ({
  endPoint,
  dataToSend,
  paramsData,
  id,
}) => {
  try {
    return await axios({
      method: "PATCH",
      url: id ? `${ApiConfig[endPoint]}/${id}/` : ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getDataHandlerAPI = async ({
  endPoint,
  dataToSend,
  paramsData,
}) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
