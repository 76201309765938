import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { FineXimLogo } from "../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "center",
    zIndex: 2000,
  },
}));

export default function PageLoading() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className="displayColumn">
        <Box>
          <img
            style={{ width: "auto", maxWidth: "200px" }}
            src="/images/logo.png"
            alt="loader"
          />
        </Box>
      </Box>
    </Box>
  );
}
