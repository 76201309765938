import React, { useContext, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    width: "100%",
    "& .MuiButton-root": {
      "&:hover": {
        color: "#8247FF",
        "& $icon": {
          color: "#8247FF",
        },
      },
    },
    "& .active": {
      color: "#8247FF !important",
      // background: "#22353e",
      borderRadius: "10px",
      width: "100%",
      fontWeight: theme.typography.fontWeightRegular,
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
      "& $icon": {
        color: "#8247FF",
      },
    },
  },
  button: {
    color: theme.palette.primary.main,
    padding: "13px 20px",
    justifyContent: "flex-start",
    textTransform: "none",
    marginBottom: "8px",
    width: "100%",
  },

  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
    color: "rgba(255, 255, 255, 0.6)",
  },
  title: {
    marginRight: "auto",
    // marginLeft: "6px",
  },
  active: {
    color: "#10C6FF !important",
    background: "#22353e",
    borderRadius: "10px",
    fontWeight: theme.typography.fontWeightRegular,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "#10C6FF",
    },
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(openProp);
  const history = useNavigate();
  const isActive = window.location.pathname === href;
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle}>
          {Icon && (
            <Icon className={classes.icon} size="20" style={{ fill: "#fff" }} />
          )}
          <span className={classes.title}>{title}</span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={clsx(
          classes.buttonLeaf,
          `depth-${depth}`,
          isActive && "active"
        )}
        style={{ width: "100%" }}
        onClick={() =>
          history(href, {
            state: {
              isEdit:
                auth?.userData?.userType === "ADMIN"
                  ? true
                  : rest?.item?.isEdit,
            },
          })
        }
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
        {Info && <Info />}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
