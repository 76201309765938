import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar, { sections, subAdmin } from "./NavBar";
import TopBar from "./TopBar";
import { Box } from "@material-ui/core";
import { AuthContext } from "src/context/Auth";
import { useNavigate } from "react-router-dom";
import { getUpdatedRoutes, routeGroups } from "src/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // height: "100%",
    // overflow: "hidden",
    width: "100%",
    height: "100vh",
  },
  root: {
    backgroundColor: "#100E12",
    // position: "relative",
    height: "100vh",
  },

  wrapper: {
    paddingTop: 70,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 267,
    },
    "@media (max-width:767px)": {
      paddingTop: "64px !important",
    },
  },
  contentContainer: {
    display: "flex",
    // flex: "1 1 auto",
    // overflow: "hidden",
  },
  content: {
    // flex: "1 1 auto",
    // height: "100vh",
    overflow: "auto",
    // position: "relative",
    // background: "#251327",
    background: "#100E12",
    padding: "28px 28px 25px 30px",
    // marginBottom: "40px",
    [theme.breakpoints.down("md")]: {
      padding: "25px 15px 10px ",
    },
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  // const themeSeeting = useContext(SettingsContext);
  const auth = useContext(AuthContext);
  const router = useNavigate();

  useEffect(() => {
    if (auth?.userData) {
      const matchedItems = [...sections, ...subAdmin].flatMap((section) =>
        section.items.filter(
          (item) =>
            auth?.userData?.permissions &&
            [
              ...auth?.userData?.permissions?.map((item) => item.name),
              ...["Dashboard", "Settings"],
            ].includes(item.title)
        )
      );
      const matchedArray = matchedItems?.map((item) => item.href);
      const result = getUpdatedRoutes(matchedArray, routeGroups);
      const matchFound = result.includes(window.location.pathname);
      if (
        auth.userLoggedIn &&
        auth?.userData?.userType == "SUBADMIN" &&
        !matchFound
      ) {
        router("/dashboard");
      }
    }
  }, [auth, window.location.pathname, sections, subAdmin]);

  return (
    <Box className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <Box className={classes.wrapper}>
        <Box className={classes.content} id="main-scroll">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
